import service from '@/utils/request';

// @Tags RmMenuUrl
// @Summary 创建RmMenuUrl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuUrl true "创建RmMenuUrl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmMenuUrl/createRmMenuUrl [post]
export var createRmMenuUrl = function createRmMenuUrl(data) {
  return service({
    url: "/rmMenuUrl/createRmMenuUrl",
    method: 'post',
    data: data
  });
};

// @Tags RmMenuUrl
// @Summary 删除RmMenuUrl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuUrl true "删除RmMenuUrl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmMenuUrl/deleteRmMenuUrl [delete]
export var deleteRmMenuUrl = function deleteRmMenuUrl(data) {
  return service({
    url: "/rmMenuUrl/deleteRmMenuUrl",
    method: 'delete',
    data: data
  });
};

// @Tags RmMenuUrl
// @Summary 删除RmMenuUrl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmMenuUrl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmMenuUrl/deleteRmMenuUrl [delete]
export var deleteRmMenuUrlByIds = function deleteRmMenuUrlByIds(data) {
  return service({
    url: "/rmMenuUrl/deleteRmMenuUrlByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmMenuUrl
// @Summary 更新RmMenuUrl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuUrl true "更新RmMenuUrl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmMenuUrl/updateRmMenuUrl [put]
export var updateRmMenuUrl = function updateRmMenuUrl(data) {
  return service({
    url: "/rmMenuUrl/updateRmMenuUrl",
    method: 'put',
    data: data
  });
};

// @Tags RmMenuUrl
// @Summary 用id查询RmMenuUrl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenuUrl true "用id查询RmMenuUrl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmMenuUrl/findRmMenuUrl [get]
export var findRmMenuUrl = function findRmMenuUrl(params) {
  return service({
    url: "/rmMenuUrl/findRmMenuUrl",
    method: 'get',
    params: params
  });
};

// @Tags RmMenuUrl
// @Summary 分页获取RmMenuUrl列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmMenuUrl列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmMenuUrl/getRmMenuUrlList [get]
export var getRmMenuUrlList = function getRmMenuUrlList(params) {
  return service({
    url: "/rmMenuUrl/getRmMenuUrlList",
    method: 'get',
    params: params
  });
};